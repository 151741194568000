import * as React from 'react';
import { GoConfigLink, StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const TemperatureCard = ({ data, config }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    if (!data?.sensors.temperature) {
        return <StatusCard title="Temperature" badgeStatus="error" badgeLabel="Not available">
            Tracker Temperature data is not available.
        </StatusCard>
    }

    const message = <>Tracker’s temperature is <strong>{data?.sensors.temperature.toFixed(1)} °C</strong></>;

    if (data?.alerts?.includes('temperatureLow')) {
        return <StatusCard title="Temperature" badgeStatus="warning" badgeLabel="Low">{message}</StatusCard>
    } else if (data?.alerts?.includes('temperatureHigh')) {
        return <StatusCard title="Temperature" badgeStatus="warning" badgeLabel="High">{message}</StatusCard>
    } else if (!(config?.alerts.temperatureHigh?.enabled === true || config?.alerts.temperatureLow?.enabled === true)) {
        return <StatusCard title="Temperature" badgeStatus="disabled" badgeLabel="Disabled">
            {message}<br/>
            Temperature level alert is not enabled. <br/><GoConfigLink/>
        </StatusCard>
    } else return (
        <StatusCard title="Temperature">{message}</StatusCard>
    );
};
