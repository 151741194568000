import * as React from 'react';
import { StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";
import { Tooltip } from "@twilio-paste/core";
import { formatFullDate } from "../../util/format-date";
import moment from "moment";
import mccmnc from 'mcc-mnc-list';

const ONLINE_THRESHOLD_MINUTES = 30;
const OFFLINE_THRESHOLD_MINUTES = 1440;

export const ConnectionCard = ({ data, }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    const online = moment().diff(moment.unix(data?.timestamp || 0), 'minutes') < ONLINE_THRESHOLD_MINUTES;
    const offline = moment().diff(moment.unix(data?.timestamp || 0), 'minutes') > OFFLINE_THRESHOLD_MINUTES;
    const network = mccmnc.find({ mcc: data?.cellInfo?.mcc, mnc: data?.cellInfo?.mnc });

    if (data?.timestamp != undefined) {
        return (
            <StatusCard title="Cellular Connection"
                        badgeStatus={online ? 'success' : (offline ? 'error' : 'disabled')}
                        badgeLabel={online ? 'Online' : (offline ? 'Offline' : undefined)}>
                Tracker last connected <Tooltip text={formatFullDate(data?.timestamp)}
                                                unstable_timeout={500}>
                <span className="textLink">{moment(data?.timestamp * 1000).fromNow()}</span>
            </Tooltip>{online && data.cellInfo?.timestamp ? <><br/>to {network ? network.operator : 'cellular data'}.
                Signal strength {data.cellInfo.signalStrength} dB.
            </> : '.'}
            </StatusCard>)
    } else {
        return (
            <StatusCard title="Cellular Connection" badgeStatus="error" badgeLabel="Not available">
                No connection data.
            </StatusCard>
        );
    }
};