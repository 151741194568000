import moment from 'moment';

export const formatDate = (date: Date | number): string => {
    const now = moment(new Date());
    const targetDate = date instanceof Date ? moment(date) : moment.unix(date);
    let formattedDate: string | undefined;

    if (targetDate.isSame(now, 'day')) {
        formattedDate = targetDate.format('hh:mm A');
    } else if (targetDate.isSame(now, 'year')) {
        formattedDate = targetDate.format('MMM D');
    } else {
        formattedDate = targetDate.format('MMM D, YYYY');
    }

    return `${formattedDate} (${targetDate.fromNow()})`;
};

export const formatFullDate = (date: number): string => {
    return moment.unix(date).utc().format('hh:mm:ss UTC YYYY-MM-DD')
}
