import React from 'react';
import { Box, Heading, Paragraph, Table, TBody, Td, Th, THead, Tooltip, Tr } from '@twilio-paste/core';
import { statusStore } from 'store/status-store';
import { constants } from '../constants';
import { observer } from 'mobx-react-lite';
import { formatDate, formatFullDate } from 'util/format-date';

export const LogsTab = observer((): JSX.Element => {
    const {loading, deviceStatus } = statusStore;

    const eventsToShow = deviceStatus?.alertsHistory && deviceStatus?.alertsHistory
        .slice()
        .sort((entry1, entry2) => entry2.ts - entry1.ts)
        .slice(0, 10);

    return (
        <Box>
            <Heading as="h3" variant="heading30">
                Alert logs
            </Heading>
            <Paragraph>
                An event is a change of state detected and reported by the device and accessible by the API. Event
                definitions are updated via the API or Config tab. Displaying the last 10 event logs below.
            </Paragraph>
            {loading ? (
                'Loading...'
            ) : (
                <Table tableLayout="fixed">
                    <THead>
                        <Tr>
                            <Th>Time stamp</Th>
                            <Th>Event</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {eventsToShow?.map((event) => (
                            <Tr key={event.ts}>
                                <Td>
                                    <Tooltip text={formatFullDate(event.ts)}
                                             unstable_timeout={500}>
                                        <span>{formatDate(event.ts)}</span>
                                    </Tooltip>
                                </Td>
                                <Td>{constants.eventType[event.alert] ?? event.alert}</Td>
                            </Tr>
                        )) ?? (
                            <Tr>
                                <Td colSpan={2}>No events found.</Td>
                            </Tr>
                        )}
                    </TBody>
                </Table>
            )}
        </Box>
    );
});
