import React, { useEffect, useMemo } from 'react';
import { matchRoutes, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@twilio-paste/core';
import * as designTokens from '@twilio-paste/design-tokens';
import { css, styled } from '@twilio-paste/styling-library';
import { LogoTwilioIcon } from '@twilio-paste/icons/esm/LogoTwilioIcon';
import { DashboardTab } from './pages/DashboardTab';
import { LogsTab } from 'pages/LogsTab';
import { IntegrationTab } from 'pages/IntegrationTab';
import { ConfigurationTab } from 'pages/ConfigurationTab';
import { statusStore } from 'store/status-store';
import { configStore } from 'store/config-store';
import { MainHeading } from './components/main-heading';
import packageJson from '../package.json'

const tabs = [
    {
        path: 'dashboard',
        label: 'Dashboard',
        component: DashboardTab,
    },
    {
        path: 'logs',
        label: 'Logs',
        component: LogsTab,
    },
    {
        path: 'integration',
        label: 'Integration',
        component: IntegrationTab,
    },
    {
        path: 'config',
        label: 'Configuration',
        component: ConfigurationTab,
    },
];

const REFRESH_TIMEOUT = 10000;

export const RootLayout = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    const { fetchStatus } = statusStore;
    const { fetchConfig } = configStore;

    useEffect(() => {
        fetchStatus();
        fetchConfig();

        setInterval(fetchStatus, REFRESH_TIMEOUT);
    }, []);



    const currentPath = useMemo(() => {
        const match = matchRoutes(tabs, location);
        return match?.[0]?.route.path;
    }, [location]);

    const AppHeader = styled.div(
        css({
            backgroundColor: designTokens.backgroundColors.colorBackgroundBrandStronger,
            color: designTokens.textColors.colorTextBrandInverse,
            display: 'flex',
            marginBottom: designTokens.space20,
        }),
    );

    const TwilioIcon = styled.div(
        css({
            padding: designTokens.spacings.space60,
            borderRightColor: designTokens.borderColors.colorBorder,
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
        }),
    );

    const HeaderText = styled.div(
        css({
            padding: designTokens.spacings.space60,
            fontSize: designTokens.fontSize40,
            fontWeight: designTokens.fontWeightSemibold,
        }),
    );

    const Version = styled.div(
        css({
            padding: designTokens.spacings.space60,
            fontSize: designTokens.fontSize10,
            color: designTokens.textColors.colorTextWeaker,
            position: 'absolute',
            right: 0
        }),
    );

    const Content = styled.div(
        css({
            padding: '20px',
        }),
    );

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                height: '100vh',
            }}
        >
            <AppHeader style={{ marginBottom: 0}}>
                <Button variant="reset" as="a" href="https://console.twilio.com">
                    <TwilioIcon>
                        <LogoTwilioIcon decorative={false} title="Twilio" />
                    </TwilioIcon>
                </Button>
                <HeaderText>Programmable Asset Tracker</HeaderText>
                <Version>{`${packageJson.version}`}</Version>
            </AppHeader>
            <Box className="appContent" maxWidth={1240} >
                <Content >
                    <MainHeading />
                    <Tabs selectedId={currentPath}>
                        <TabList aria-label="Main tabs">
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.path}
                                    id={tab.path}
                                    onMouseDown={(e): void => {
                                        navigate(tab.path);
                                        e.preventDefault();
                                    }}
                                >
                                    {tab.label}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            {tabs.map((tab) => (
                                <TabPanel key={tab.path}>
                                    <tab.component />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                    <Routes>
                        <Route
                            index
                            element={<Navigate to={{ pathname: tabs[0].path }} replace={true} />}
                        />
                        {tabs.map((tab) => (
                            <Route key={tab.path} path={tab.path} />
                        ))}
                    </Routes>
                </Content>
            </Box>
        </Box>
    );
};
