import React, { useCallback } from 'react';
import {
    Box,
    Button,
    Heading,
    ListItem,
    ModalBody,
    ModalHeading,
    OrderedList,
    Paragraph,
    Popover,
    PopoverButton,
    PopoverContainer,
    Table,
    TBody,
    Td,
    TextArea,
    THead,
    Toaster,
    Tr,
    useToaster,
} from '@twilio-paste/core';
import { observer } from 'mobx-react-lite';
import { CopyIcon } from '@twilio-paste/icons/esm/CopyIcon';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';

import { configStore } from '../store/config-store';
import { formatDate } from '../util/format-date';
import { LinkExternalIcon } from "@twilio-paste/icons/cjs/LinkExternalIcon";

export const ConfigurationTab = observer((): JSX.Element => {
    const toaster = useToaster();
    const { editableConfig, onConfigChanged, isChanged, configUpdatedAt, loading, fetchConfig, saveConfig } = configStore;


    const handleReset = useCallback((): void => {
        fetchConfig();
    }, [fetchConfig]);

    const handleUpdate = useCallback((): void => {
        saveConfig()
            .then(() => {
                toaster.push({
                    message: `Configuration has been successfully sent to the tracker.`,
                    variant: 'success',
                    dismissAfter: 7000,
                });
            })
            .catch((e) => {
                toaster.push({
                    message: `${e}`,
                    variant: 'error',
                    dismissAfter: 5000,
                });
            });
    }, [saveConfig, toaster]);


  const handleCopy = useCallback((): void => {
    navigator.clipboard.writeText(`${JSON.stringify(JSON.parse(editableConfig))}`.replaceAll("\"", "\\\"")).then(
      () => {
        toaster.push({
          message: 'JSON copied to clipboard',
          variant: 'success',
          dismissAfter: 5000,
        })
      }
    )
  }, [editableConfig]);

    return (
        <>
            <div className={"configHeading"}>
                <div>
                    <Heading as="h3" variant="heading30" marginBottom={'space0'}>
                        Configuration
                    </Heading>
                </div>

                <div>
                    {configUpdatedAt && (
                        <><strong>Last updated on:</strong> <span>{formatDate(configUpdatedAt)}.</span> </>
                    )}
                </div>
            </div>

            <Paragraph>

                Once you are satisfied with this configuration, download the JSON and deploy it to a
                fleet of production devices. Learn more about each alert settings customization.
            </Paragraph>

            <Table aria-label="Edit current settings">
                <THead>
                    <Tr>
                        <Td>
                            <strong>Edit current configuration</strong>
                        </Td>
                        <Td>
                            <Box style={{ textAlign: 'right', marginBottom: '-5px' }}>
                                <Button
                                    variant="link"
                                    onClick={handleCopy}
                                >
                                    <CopyIcon decorative={false} title="Copy JSON"/>
                                    Copy JSON
                                </Button>
                            </Box>
                        </Td>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td colSpan={2}>
                            <TextArea
                              placeholder="Config loading..." onChange={(e)=>{
                                onConfigChanged(e.target.value.toString())
                            }}        aria-describedby="JSON Config"
                                      value={editableConfig}
                                      id="message" name="message" resize="vertical" required />

                            {/*<textarea style={{width: '100%', height: '100vh'}} value={editableConfig} onChange={(e)=>{*/}
                            {/*    onConfigChanged(e.target.value.toString())*/}
                            {/*}}/>*/}
                        </Td>
                    </Tr>
                </TBody>
            </Table>

            <Table aria-label="Controls" variant="borderless" cellPadding={10}>
                <TBody>
                    <Tr>
                        <Td>
                            <Button
                                variant={'primary'}
                                loading={loading}
                                onClick={handleUpdate}
                                disabled={!isChanged}
                            >
                                Save configuration{' '}
                            </Button>
                            &nbsp;&nbsp;
                            {isChanged && (
                                <Button variant={'link'} onClick={handleReset}>
                                    Reset to current settings
                                </Button>
                            )}
                        </Td>
                        <Td>
                            <Box style={{ textAlign: 'right' }}>
                                <PopoverContainer baseId="popover">
                                    <PopoverButton variant="secondary">
                                        <InformationIcon decorative/>
                                        Next step: Promote to Fleet
                                    </PopoverButton>
                                    <NextStepDialog onCopyClick={handleCopy}/>
                                </PopoverContainer>
                            </Box>
                        </Td>
                    </Tr>
                </TBody>
            </Table>


            <Toaster {...toaster} />
        </>
    );
});


export const NextStepDialog = ({
                                   onCopyClick
                               }: { onCopyClick: () => void }): JSX.Element => (

    <Popover aria-label="Popover">
        <>

            <Box padding={"space50"}>
                <ModalHeading as="h3">
                    Next step: Promote to Fleet
                </ModalHeading>
            </Box>

            <ModalBody>
                <Paragraph>
                    Current configuration setting can be deployed to a fleet of devices
                    via the
                    Fleet Management Dashboard. Follow few easy steps below to Promot
                    current
                    configurations to the Fleet.
                </Paragraph>
                <OrderedList>
                    <ListItem>
                        <Button
                            variant="link"
                            onClick={onCopyClick}
                        >
                            Copy configuration as JSON{' '}
                            <CopyIcon decorative={false} title="Copy JSON"/>
                        </Button>
                    </ListItem>
                    <ListItem>Login to <Button variant={"link"}
                                               onClick={(): void => {
                                                   window.open('https://impcentral.ei.run/login', '_blank')
                                               }}
                                               target={"_blank"}>
                        impCentral Device management Dashboard <LinkExternalIcon
                        decorative={false} title="Open"/>
                    </Button>

                    </ListItem>
                    <ListItem>
                        Find your Fleet using targeted Device Group, and paste the JSON
                        in the
                        Environment Variables tab.
                    </ListItem>
                </OrderedList>
            </ModalBody>
        </>
    </Popover>
)

