import * as React from 'react';
import { StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const MechanicalShockCard = ({ data }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    if (data?.alerts?.includes('shockDetected')) {
        return (
            <StatusCard title="Mechanical Shock"
                        badgeStatus="warning"
                        badgeLabel="Detected">
                Shock to the Tracker is detected.
            </StatusCard>)
    }

    return (
        <StatusCard title="Mechanical Shock">
            No Shocks to the Tracker was detected.
        </StatusCard>
    );
};
