import * as React from 'react';
import { GenericThemeShape, ThemeVariants } from '@twilio-paste/core/theme';
import { CustomizationProvider } from '@twilio-paste/core/customization';

import './app.css';

const CustomizationProviderPatched = CustomizationProvider as React.FC<{
    baseTheme?: ThemeVariants;
    theme?: Partial<GenericThemeShape>;
    children: React.ReactNode;
}>;

export const App = ({ children }: React.PropsWithChildren): JSX.Element => (
    <CustomizationProviderPatched
        baseTheme="default"
    >
        {children}
    </CustomizationProviderPatched>
);
