import React from 'react';
import { Box } from '@twilio-paste/core';
import PropTypes from 'prop-types';
import { SpaceOptions } from '@twilio-paste/style-props';

const contentPropTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

const FLIP_CONTENT_TYPE = 'FlipContent';
const STATIONARY_CONTENT_TYPE = 'StationaryContent';

const FlipContent = ({ children }: React.PropsWithChildren): JSX.Element => <>{children}</>;
FlipContent.propTypes = contentPropTypes;
FlipContent.defaultProps = {
    __TYPE: FLIP_CONTENT_TYPE,
};

const StationaryContent = ({ children }: React.PropsWithChildren): JSX.Element => <>{children}</>;
StationaryContent.propTypes = contentPropTypes;
StationaryContent.defaultProps = {
    __TYPE: STATIONARY_CONTENT_TYPE,
};

interface FlipBoxProps {
    children: JSX.Element[];
    marginBottom?: SpaceOptions;
}

interface ContentProps {
    children: React.ReactNode;
    __TYPE?: string;
}

const FlipBox = ({ children, marginBottom }: FlipBoxProps): JSX.Element => {
    let flipContent: React.ReactNode | undefined;
    let stationaryContent: React.ReactNode | undefined;

    React.Children.forEach(children, (child) => {
        if ((child.props as ContentProps).__TYPE === FLIP_CONTENT_TYPE) {
            flipContent = child;
        } else if ((child.props as ContentProps).__TYPE === STATIONARY_CONTENT_TYPE) {
            stationaryContent = child;
        }
    });

    return (
        <Box
            element="GRID"
            display="flex"
            flexDirection={['column', 'column', 'row-reverse']}
            marginBottom={marginBottom ?? 'space0'}
        >
            <Box flex={1}></Box>
            <Box
                flex={2}
                paddingTop={['space0', 'space0', 'space50']}
                marginBottom={['space60', 'space60', 'space0']}
            >
                {flipContent}
            </Box>
            <Box flex={1}></Box>
            <Box flex={2}>{stationaryContent}</Box>
        </Box>
    );
};

export { FlipBox, FlipContent, StationaryContent };
