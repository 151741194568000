import React from 'react';
import { Heading, } from '@twilio-paste/core';
import { observer } from 'mobx-react-lite';

import { configStore } from '../store/config-store';


export const MainHeading = observer((): JSX.Element => {
    const { deviceConfig } = configStore;
    return (
        <Heading as="h1" variant="heading10">
            Asset Tracker: {deviceConfig?.description?.trackerId.substr(6)}
        </Heading>
    );
});
