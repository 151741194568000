import React, { useEffect } from 'react';
import { Heading, Paragraph } from '@twilio-paste/core';
import { Circle, MapContainer, TileLayer, Tooltip, useMap } from 'react-leaflet';
import { Location } from 'api/types/device-status';
import { GeoFence } from 'api/types/device-configuration';

import 'leaflet/dist/leaflet.css';

interface MapProps {
    location?: Location;
    geofence?: GeoFence;
    center?: [number, number];
    zoom?: number;
}

interface TooltipProps {
    title: string;
    lat: number;
    lng: number;
    radius?: number;
}

export const MapViewControl = (props: MapProps): JSX.Element | null => {
    const map = useMap();

    useEffect(() => {
        setTimeout(() => map.invalidateSize(), 100);
    }, [map]);

    if (props.center) {
        map.setView(props.center, props.zoom, { animate: true });
    } else {
        map.setView([props.location?.lat ?? 37.0895217, props.location?.lng ?? -98.8615469],
            props.zoom, { animate: true });
    }

    return null;
};

export const PositionedTooltip = ({ title, lat, lng, radius }: TooltipProps): JSX.Element => {
    return (
        <Tooltip direction="left" offset={[-10, 0]} opacity={1} permanent>
            <Heading as="h6" variant="heading50" marginBottom="space0">
                {title}
            </Heading>
            <Paragraph marginBottom="space0">Latitude: {lat.toFixed(5)}&deg;</Paragraph>
            <Paragraph marginBottom="space0">Longitude: {lng.toFixed(5)}&deg;</Paragraph>
            {radius ?
                <Paragraph marginBottom="space0">Accuracy: {Math.round(radius).toLocaleString()} m</Paragraph> : null}
        </Tooltip>
    );
};

export const Map = ({ location, center, geofence }: MapProps): JSX.Element => {
    return (
        <MapContainer
            style={{ width: '100%', height: '360px' }}
            scrollWheelZoom={false}
        >
            <MapViewControl center={center} location={location} zoom={location !== undefined ? 15 : 3}
                            geofence={geofence}/>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <Circle
                center={[location?.lat ?? 0, location?.lng ?? 0]}
                radius={location?.accuracy ?? 0}
                pathOptions={{
                    fillColor: 'rgb(235, 86, 86)',
                    fillOpacity: 0.5,
                    color: 'none',
                }}
            >
                {location !== undefined ? (
                    <PositionedTooltip
                        title="Tracker location:"
                        lat={location.lat}
                        lng={location.lng}
                        radius={location.accuracy}
                    />
                ) : null}
            </Circle>
            {geofence !== undefined ? (
                <Circle
                    center={[geofence?.lat ?? 0, geofence?.lng ?? 0]}
                    radius={geofence?.radius ?? 0}
                    pathOptions={{
                        fillColor: 'rgb(255, 166, 41)',
                        fillOpacity: 0.5,
                        color: 'none',
                    }}
                >
                    <PositionedTooltip title="Geofence:" lat={geofence.lat} lng={geofence.lng}/>
                </Circle>
            ) : null}
        </MapContainer>
    );
};
