export const constants = {
    eventType: {
        temperatureHigh: 'Temperature High',
        temperatureLow: 'Temperature Low',
        shockDetected: 'Shock detected',
        batteryLow: 'Battery Low',
        tamperingDetected: 'Tampering detected',
        motionStarted: 'Motion started',
        motionStopped: 'Motion stopped',
        geofenceEntered: 'Geofence entered',
        geofenceExited: 'Geofence exited',
        repossessionActivated: 'Repossession activated',
    },
};
