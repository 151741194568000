import { action, makeAutoObservable, observable } from 'mobx';
import { apiClient } from 'api';
import { DeviceStatus } from 'api/types/device-status';

class StatusStore {
    @observable public loading = false;
    @observable public deviceStatus: DeviceStatus | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    @action.bound
    public fetchStatus = async () : Promise<void> => {
        if(this.loading) return;
        try {
            this.loading = true;
            this.deviceStatus = await apiClient.getDeviceStatus();
        } catch (e) {
            console.error('StatusStore.fetchStatus', e);
        } finally {
            this.loading = false;
        }
    }
}

export const statusStore = new StatusStore();
