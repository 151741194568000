import React, { useCallback, useState } from 'react';
import { Box, Button, Combobox, Heading, Input, Label, Separator, Toaster, useToaster, } from '@twilio-paste/core';
import { observer } from 'mobx-react-lite';
import { FlipBox, FlipContent, StationaryContent } from 'components/flip-box';
import { apiClient } from 'api';

const apiTypes = ['Custom'];

export const IntegrationTab = observer((): JSX.Element => {
    const toaster = useToaster();

    const [token, setToken] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [apiType, setApiType] = useState(apiTypes[0]);
    const [apiUrl, setApiUrl] = useState('');
    const [apiUsername, setApiUsername] = useState('');
    const [apiPassword, setApiPassword] = useState('');

    const setTokens = useCallback(async () => {

        try {
            await apiClient.updateTokens({
                ublox: token ? token : undefined,
                gmaps: apiKey ? apiKey : undefined,
            });

            toaster.push({
                id: 'token_success',
                message: 'Tokens have been successfully saved and updated.',
                variant: 'success',
                dismissAfter: 5000,
            });
        } catch (err) {
            toaster.push({
                id: 'token_error',
                message: 'API system error',
                variant: 'error',
                dismissAfter: 5000,
            });
        }
    }, [token, apiKey]);

    const setCloudSettings = useCallback(async () => {
        // TODO: not sure if we want to create a store for this
        try {
            await apiClient.updateCloudSettings({
                url: apiUrl,
                user: apiUrl,
                pass: apiPassword,
            });

            toaster.push({
                id: 'settings_success',
                message: 'Cloud settings have been successfully saved and updated.',
                variant: 'success',
                dismissAfter: 5000,
            });
        } catch (err) {
            toaster.push({
                id: 'settings_failure',
                message: 'API system error',
                variant: 'error',
                dismissAfter: 5000,
            });
        }
    }, [apiType, apiUrl, apiUsername, apiPassword]);

    return (
        <>
            <Toaster {...toaster} />
            <Heading as="h2" variant="heading30">
                Location Token &ndash; Integration
            </Heading>
            <FlipBox>
                <FlipContent>
                    Improve Asset Tracker’s location accuracy using U-Blox To significantly improve accuracy for
                    location data reporting, the application leverages U-Blox Assist Now for GNSS (satellite) and Google
                    Gelocation API for Wi-Fi and cellular-based location. Learn more about how you can obtain these
                    tokens.
                </FlipContent>
                <StationaryContent>
                    <Box marginBottom="space60">
                        <Label htmlFor="token">U-blox Assist Now token</Label>
                        <Input
                            id="token"
                            name="token"
                            type="text"
                            value={token}
                            onChange={(e): void => setToken(e.target.value)}
                        />
                    </Box>
                    <Box marginBottom="space60">
                        <Label htmlFor="google-api-key">Google Geolocation API key</Label>
                        <Input
                            id="google-api-key"
                            name="google-api-key"
                            type="text"
                            value={apiKey}
                            onChange={(e): void => setApiKey(e.target.value)}
                        />
                    </Box>
                    <Button variant="primary" onClick={setTokens}>
                        Save tokens
                    </Button>
                </StationaryContent>
            </FlipBox>

            <Separator orientation="horizontal" verticalSpacing="space90"/>

            <Heading as="h2" variant="heading30">
                API - Cloud integration
            </Heading>
            <FlipBox>
                <FlipContent>
                    Cloud integration. Select one of the options below to push your device data into an asset tracking
                    cloud portal. Twilio has provided out-of-the-box integrations with a few partners. Alternatively,
                    you can push data to your own custom cloud. Learn more about 3rd party cloud integration.
                </FlipContent>
                <StationaryContent>
                    <Box marginBottom="space60">
                        <Combobox
                            items={apiTypes}
                            labelText="Cloud REST API"
                            selectedItem={apiType}
                            onSelectedItemChange={(e): void => setApiType(e.selectedItem)}
                        />
                    </Box>
                    <Box marginBottom="space60">
                        <Label htmlFor="api-url">
                            Cloud REST API URL
                        </Label>
                        <Input
                            id="api-url"
                            name="api-url"
                            type="text"
                            required
                            value={apiUrl}
                            onChange={(e): void => setApiUrl(e.target.value)}
                        />
                    </Box>
                    <Box marginBottom="space60">
                        <Label htmlFor="api-username">
                            Cloud REST API username
                        </Label>
                        <Input
                            id="api-username"
                            name="api-username"
                            type="text"
                            required
                            value={apiUsername}
                            onChange={(e): void => setApiUsername(e.target.value)}
                        />
                    </Box>
                    <Box marginBottom="space60">
                        <Label htmlFor="api-password">
                            Cloud REST API password
                        </Label>
                        <Input
                            id="api-password"
                            name="api-password"
                            type="text"
                            // insertAfter={<HideIcon decorative={false} title="Password"/>}
                            required
                            value={apiPassword}
                            onChange={(e): void => setApiPassword(e.target.value)}
                        />
                    </Box>
                    <Button variant="primary" onClick={setCloudSettings}>
                        Save Cloud settings
                    </Button>
                </StationaryContent>
            </FlipBox>
        </>
    );
});
