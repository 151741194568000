import React, { useState } from 'react';
import { statusStore } from 'store/status-store';
import { observer } from 'mobx-react-lite';
import { Column, Grid, Heading } from '@twilio-paste/core';
import { configStore } from 'store/config-store';
import { Map } from 'components/map';
import { TemperatureCard } from "../components/statuscards/TemperatureCard";
import { BatteryCard } from "../components/statuscards/BatteryCard";
import { MechanicalShockCard } from "../components/statuscards/MechanicalShockCard";
import { TamperingCard } from "../components/statuscards/TamperingCard";
import { RepossessionCard } from "../components/statuscards/RepossessionCard";
import { MotionCard } from "../components/statuscards/MotionCard";
import { GeofenceCard } from "../components/statuscards/GeofenceCard";
import { LocationCard } from "../components/statuscards/LocationCard";
import { ConnectionCard } from "../components/statuscards/ConnectionCard";
import { Spinner } from "@twilio-paste/core/spinner";

export const DashboardTab = observer((): JSX.Element => {
    const { deviceStatus, loading } = statusStore;
    const { deviceConfig } = configStore;
    const [mapCenter, setMapCenter] = useState<[number, number]>();
    const data = deviceStatus?.latestData;
    const config = deviceConfig?.configuration;
    const statusCardProps = { data: deviceStatus?.latestData, config: deviceConfig?.configuration };

    return (
        <>
            <Heading as="h3" variant="heading30">
                <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                    <div>Dashboard</div>
                    {loading && <Spinner decorative={false} title="Loading" size="sizeIcon30"/>}
                </div>
            </Heading>
            <Map
                location={data?.location}
                zoom={2}
                center={mapCenter}
                geofence={config?.locationTracking.geofence}
            />

            {!deviceStatus ? <></> :
                <Grid vertical gutter="space30" marginTop="space40">
                    <Column>
                        <Grid
                            equalColumnHeights={true}
                            vertical={[true, true, false]}
                            gutter="space30">
                            <Column>
                                <ConnectionCard {...statusCardProps} />
                            </Column>
                            <Column>
                                <BatteryCard {...statusCardProps} />
                            </Column>
                            <Column>
                                <TemperatureCard {...statusCardProps}/>
                            </Column>
                        </Grid>
                    </Column>
                    <Column>
                        <Grid
                            equalColumnHeights={true}
                            vertical={[true, true, false]}
                            gutter="space30">
                            <Column>
                                <MechanicalShockCard {...statusCardProps} />
                            </Column>
                            <Column>
                                <TamperingCard {...statusCardProps} />
                            </Column>
                            <Column>
                                <RepossessionCard {...statusCardProps} />
                            </Column>
                        </Grid>
                    </Column>
                    <Column>
                        <Grid
                            equalColumnHeights={true}
                            vertical={[true, true, false]}
                            gutter="space30">
                            <Column>
                                <LocationCard {...statusCardProps}
                                              onClickLocate={(): void =>
                                                  setMapCenter([data?.location.lat || 0, data?.location.lng || 0])
                                              }/>
                            </Column>
                            <Column>
                                <GeofenceCard {...statusCardProps}
                                              onClickLocate={(): void => {
                                                  setMapCenter([
                                                      config?.locationTracking.geofence.lat || 0,
                                                      config?.locationTracking.geofence.lng || 0])
                                              }}/>
                            </Column>
                            <Column>
                                <MotionCard {...statusCardProps} />
                            </Column>
                        </Grid>

                    </Column>
                </Grid>}
        </>
    );
});
