import * as React from 'react';
import { GoConfigLink, StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const TamperingCard = ({ data, config }: { data?: DeviceData, config?: Configuration }): JSX.Element => {


    if (config?.alerts.tamperingDetected?.enabled === false) {
        return (
            <StatusCard title="Tampering"
                        badgeStatus="disabled"
                        badgeLabel="Disabled">
                Tracker Tampering alert is not enabled. <br/><GoConfigLink/>
            </StatusCard>)
    }

    if (data?.alerts?.includes('tamperingDetected')) {
        return (
            <StatusCard title="Tampering"
                        badgeStatus="warning"
                        badgeLabel="Tampered">
                Tampering to the Tracker was detected.
            </StatusCard>)
    }

    return (
        <StatusCard title="Tampering">
            No tampering to the Tracker was detected.
        </StatusCard>
    );
};
