import * as React from 'react';
import { GoConfigLink, StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const BatteryCard = ({ data, config }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    if (!data?.sensors.batteryLevel) {
        return <StatusCard title="Battery level" badgeStatus="error" badgeLabel="Not available">
            Tracker Battery level data is not available.
        </StatusCard>
    }

    const message = <>Tracker’s battery level is <strong>{data?.sensors.batteryLevel.toFixed(1)}%</strong></>;

    if (data?.alerts?.includes('batteryLow')) {
        return <StatusCard title="Battery level" badgeStatus="warning" badgeLabel="Low">{message}</StatusCard>
    }

    if (!(config?.alerts.batteryLow?.enabled === true)) {
        return <StatusCard
            title="Battery level" badgeStatus="disabled" badgeLabel="Disabled">
            {message}<br/>
            Tracker Battery level alert is not enabled.<br/><GoConfigLink/>
        </StatusCard>
    }

    return (<StatusCard title="Battery level">{message}</StatusCard>);
};
