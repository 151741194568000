import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { CloudSettings } from './types/cloud-settings';
import { DeviceConfiguration } from './types/device-configuration';
import { DeviceStatus } from './types/device-status';
import { Tokens } from './types/tokens';

class ApiClient {
    private async fetch(request: AxiosRequestConfig): Promise<unknown> {
        const agentId = window.location.pathname.replaceAll('/', '');
        request.url = `/${agentId}${request.url}`;
        request.paramsSerializer = (params: object): string =>
            qs.stringify(params, {
                allowDots: true,
                arrayFormat: 'repeat',
            });

        if (request.headers !== undefined) {
            request.headers['Accept'] = 'application/json';
        }

        try {
            const response = await axios(request);

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            throw new Error(response.data);
        } catch (error) {
            const data = (error as AxiosError)?.response?.data;
            console.error('API request exception', data);
            throw new Error(data ? data.toString() : error?.toString());
        }
    }

    public async getDeviceStatus(): Promise<DeviceStatus> {
        return this.fetch({
            method: 'GET',
            url: '/web-ui/data',
        }) as Promise<DeviceStatus>;
    }

    public async getDeviceConfig(): Promise<DeviceConfiguration> {
        return this.fetch({
            method: 'GET',
            url: '/cfg',
        }) as Promise<DeviceConfiguration>;
    }

    public async updateDeviceConfig(config?: string): Promise<void> {
        return this.fetch({
            method: 'PATCH',
            url: '/cfg',
            data: config,
        }) as Promise<void>;
    }

    public async updateTokens(tokens: Tokens): Promise<void> {
        return this.fetch({
            method: 'PATCH',
            url: '/web-ui/tokens',
            data: tokens,
        }) as Promise<void>;
    }

    public async updateCloudSettings(settings: CloudSettings): Promise<void> {
        return this.fetch({
            method: 'PATCH',
            url: '/web-ui/cloud-settings',
            data: settings,
        }) as Promise<void>;
    }
}

export const apiClient = new ApiClient();
