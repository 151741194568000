import * as React from 'react';
import { GoConfigLink, StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";
import { Button } from "@twilio-paste/core";

export const GeofenceCard = ({
                                 data,
                                 config,
                                 onClickLocate
                             }: { data?: DeviceData, config?: Configuration, onClickLocate: () => void  }): JSX.Element => {

    const coordinates = config?.locationTracking.geofence.lat && config?.locationTracking.geofence.lng ? <>
        <div>Latitude: {config?.locationTracking.geofence.lat}&deg;</div>
        <div>Longitude: {config?.locationTracking.geofence.lng}&deg;</div>
        <div>Radius: {config?.locationTracking.geofence.radius} m <Button variant={"link"}
                                                                          onClick={onClickLocate}>Show on the map</Button>
        </div>
    </> : null

    if (config?.locationTracking.geofence?.enabled === false) {
        return (
            <StatusCard title="Geofence"
                        badgeStatus="disabled"
                        badgeLabel="Disabled">
                Tracker Geofence alert is not enabled.<br/>
                <GoConfigLink/>
            </StatusCard>)
    } else if (data?.alerts?.includes('geofenceEntered')) {
        return (
            <StatusCard title="Geofence" badgeStatus="neutral" badgeLabel="Entered">
                <div>Tracker entered the geofence.</div>
                {coordinates}
            </StatusCard>)
    } else if (data?.alerts?.includes('geofenceExited')) {
        return (
            <StatusCard title="Geofence" badgeStatus="neutral" badgeLabel="Exited">
                <div>Tracker exited the geofence.</div>
                {coordinates}
            </StatusCard>)
    } else {

        return (
            <StatusCard title="Geofence">
                <div>Tracker Geofence location:</div>
                {coordinates}
            </StatusCard>
        );
    }
};
