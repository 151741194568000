import { action, makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from 'api';
import { DeviceConfiguration } from 'api/types/device-configuration';

class ConfigStore {
    public loading = false;
    public deviceConfig: DeviceConfiguration | undefined;
    public editableConfig = '';
    public isChanged = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchConfig(): Promise<void> {
        try {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.deviceConfig = await apiClient.getDeviceConfig();
            this.editableConfig =  JSON.stringify({
                    agentConfiguration: { ...this.deviceConfig.agentConfiguration },
                    configuration: { ...this.deviceConfig.configuration }
                }, undefined, 4)
            this.isChanged=false;
        } catch (e) {
            console.error('ConfigStore.fetchConfig', e);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action.bound
    public onConfigChanged = (config: string) => {
      runInAction(() => {
        this.editableConfig = config;
        this.isChanged = true;
      });
    };


    public async saveConfig(): Promise<void> {
        try {
            this.loading = true;
            const obj = JSON.parse(this.editableConfig);
            obj['configuration'].updateId = `${Math.round(Date.now() / 1000)}`;
            await apiClient.updateDeviceConfig(obj);
            this.isChanged=false;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public get configUpdatedAt(): number | undefined {
        return this.deviceConfig?.description?.cfgTimestamp;
    }
}

export const configStore = new ConfigStore();
