import * as React from 'react';
import { StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const MotionCard = ({ data, config }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    if (config?.locationTracking.motionMonitoring?.enabled === false) {
        return (
            <StatusCard title="Motion"
                        badgeStatus="disabled"
                        badgeLabel="Disabled">
                Tracker Motion alert is not enabled. Go to Configurations to enable this feature.
            </StatusCard>)
    }

    if (data?.alerts?.includes('motionStarted')) {
        return (
            <StatusCard title="Motion" badgeStatus="neutral" badgeLabel="Started">
                Tracker started moving.
            </StatusCard>)
    } else if (data?.alerts?.includes('motionStopped')) {
        return (
            <StatusCard title="Motion" badgeStatus="neutral" badgeLabel="Stopped">
                Tracker stopped moving.
            </StatusCard>)
    } else return (
        <StatusCard title="Motion">
            Tracker Motion data is not avaialble.
        </StatusCard>
    );
};
