import React from 'react';
import { Badge, Box, Flex, Heading, Text } from '@twilio-paste/core';
import { WarningIcon } from '@twilio-paste/icons/esm/WarningIcon';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { SuccessIcon } from '@twilio-paste/icons/esm/SuccessIcon';
import { NewIcon } from '@twilio-paste/icons/esm/NewIcon';
import { Link } from "react-router-dom";
import * as designTokens from "@twilio-paste/design-tokens";
import { ProcessDisabledIcon } from "@twilio-paste/icons/esm/ProcessDisabledIcon";

export type BadgeStatus = 'neutral' | 'warning' | 'error' | 'success' | 'new' | 'disabled';

export interface StatusCardProps {
    title: string;
    badgeStatus?: BadgeStatus;
    badgeLabel?: string;
    children?: React.ReactNode;
}

const icons: { [key in BadgeStatus]: JSX.Element } = {
    neutral: <></>,
    warning: <WarningIcon decorative/>,
    error: <ErrorIcon decorative/>,
    success: <SuccessIcon decorative/>,
    new: <NewIcon decorative/>,
    disabled: <ProcessDisabledIcon decorative/>
};

export const StatusCard = ({
                               title,
                               badgeStatus,
                               badgeLabel,
                               children,
                           }: StatusCardProps): JSX.Element => (
    <Box
        backgroundColor="colorBackgroundBody"
        borderWidth="borderWidth20"
        borderRadius="borderRadius20"
        borderColor="colorBorderWeaker"
        borderStyle="solid"
        padding="space60"
        width="100%"
    >
        <Flex>
            <Flex grow>
                <Heading as="h4" variant="heading40">
                    {title}
                </Heading>
            </Flex>
            <Flex>
                {badgeLabel && badgeStatus &&
                    <Badge as="span" variant={badgeStatus !== 'disabled' ? badgeStatus : 'decorative10'}>
                        {icons[badgeStatus]}
                        {badgeLabel}
                    </Badge>}
            </Flex>
        </Flex>

        <Flex>
            <Flex grow>
                <Text as="div" marginTop="space20">
                    {children}
                </Text>
            </Flex>
        </Flex>
    </Box>
);


export const GoConfigLink = function (): JSX.Element {
    return <>
        Go to <Link style={{ color: designTokens.textColors.colorTextLink }}
                    to={'/config'}>Configuration</Link> to enable this feature.
    </>
}