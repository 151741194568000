import * as React from 'react';
import { GoConfigLink, StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";

export const RepossessionCard = ({ data, config }: { data?: DeviceData, config?: Configuration }): JSX.Element => {

    if (config?.locationTracking.repossessionMode?.enabled === false) {
        return (
            <StatusCard title="Repossession Mode"
                        badgeStatus="disabled"
                        badgeLabel="Disabled">
                Tracker Repossession mode is not enabled.<br/><GoConfigLink/>
            </StatusCard>)
    }

    if (data?.alerts?.includes('repossessionActivated')) {
        return (
            <StatusCard title="Repossession Mode"
                        badgeStatus="neutral"
                        badgeLabel="Started">
                Tracker’s repossession mode is enabled.
            </StatusCard>)
    }

    return (
        <StatusCard title="Repossession Mode">
            Device repossession mode is disabled.
        </StatusCard>
    );
};
