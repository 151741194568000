import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { App } from './app';
import { Loading } from './components/loading';
import { RootLayout } from './root-layout';

export const Index = (): JSX.Element => {
    return (
        <App>
            <React.Suspense fallback={<Loading />}>
                <HashRouter>
                    <Routes>
                        <Route path="/*" element={<RootLayout />}></Route>
                    </Routes>
                </HashRouter>
            </React.Suspense>
        </App>
    );
};

const root = createRoot(document.querySelector('#root') as Element);

root.render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>,
);
