import * as React from 'react';
import { StatusCard } from "./StatusCard";
import { DeviceData } from "../../api/types/device-status";
import { Configuration } from "../../api/types/device-configuration";
import { Button, Tooltip } from "@twilio-paste/core";
import { formatFullDate } from "../../util/format-date";
import moment from "moment";

export const LocationCard = ({
                                 data,
                                 onClickLocate
                             }: { data?: DeviceData, config?: Configuration, onClickLocate: () => void }): JSX.Element => {

    const isValid =
        data != undefined &&
        data.location.lat !== 0 &&
        data.location.lng !== 0 &&
        data.location.accuracy <= 10000;

    const isActual = moment().diff(moment((data?.location.timestamp || 0) * 1000), 'days') < 3;

    const coordinates = isValid ? <>
        <div>Tracker last located <Tooltip text={formatFullDate(data?.location?.timestamp)}
                                            unstable_timeout={500}>
            <span className="textLink">{moment(data?.location?.timestamp * 1000).fromNow()}</span>
        </Tooltip>.</div>
        <div>Latitude: {data?.location.lat}&deg;</div>
        <div>Longitude: {data?.location.lng}&deg;</div>
        <div>Accuracy: {Math.round(data?.location.accuracy)} m <Button variant={"link"}
                                                                       onClick={onClickLocate}>Show on the map</Button>
        </div>
    </> : null

    if (isValid) {
        return (
            <StatusCard title="Location"
                        badgeStatus={isActual ? 'success' : 'warning'}
                        badgeLabel={isActual ? 'Located' : 'Obsoleted'}>
                {coordinates}
            </StatusCard>)
    } else {
        return (
            <StatusCard title="Location" badgeStatus="error" badgeLabel="Not available">
                Tracker location data is not avaialble.
            </StatusCard>
        );
    }
};